import { Title } from "../styledComponents/textComponents";


function Resume() {
  return (
    <div id="resume">
      <Title> hello, this is the resume section </Title>
      <h1>hi</h1>
    </div>
  );
}



export default Resume;